import { backgroundColor } from 'devextreme-react/cjs/chart';
import { useSelector, useDispatch } from 'react-redux';
export default function Signal() {
    const { predictedSignals, upperBand, lowerBand, signal } = useSelector((state) => state.mainSlice);
    console.log("predictedSignals", predictedSignals);
    return (
        <table class="signal-custom-border">
            <thead>
                <tr>
                    <th class="signal-custom-th">SMRT</th>
                    <th class="signal-custom-th">Pro</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="signal-custom-td">Current Position:</td>
                    <td class="signal-custom-td" style={signal === "Sell" ? {backgroundColor: "red"} : {backgroundColor: "green"}}>{signal}</td>
                </tr>
                <tr>
                    <td class="signal-custom-td">1H:</td>
                    <td class="signal-custom-td" style={predictedSignals[0] === "Bearish" ? {backgroundColor: "red"} : {backgroundColor: "green"}}>{predictedSignals[0]}</td>
                </tr>
                <tr>
                    <td class="signal-custom-td ">2H:</td>
                    <td class="signal-custom-td" style={predictedSignals[1] === "Bearish" ? {backgroundColor: "red"} : {backgroundColor: "green"}}>{predictedSignals[1]}</td>
                </tr>
                <tr>
                    <td class="signal-custom-td">4H:</td>
                    <td class="signal-custom-td" style={predictedSignals[2] === "Bearish" ? {backgroundColor: "red"} : {backgroundColor: "green"}}>{predictedSignals[2]}</td>
                </tr>
                <tr>
                    <td class="signal-custom-td">8H:</td>
                    <td class="signal-custom-td" style={predictedSignals[3] === "Bearish" ? {backgroundColor: "red"} : {backgroundColor: "green"}}>{predictedSignals[3]}</td>
                </tr>
                <tr>
                    <td class="signal-custom-td">24H:</td>
                    <td class="signal-custom-td" style={predictedSignals[4] === "Bearish" ? {backgroundColor: "red"} : {backgroundColor: "green"}}>{predictedSignals[4]}</td>
                </tr>
            </tbody>
        </table>
    )
}