import * as CustomComponents from './Components'
import './global.css'
export default function App () {
  return(
    <div className='main-div'>
      <div className='realtimetrend-candle-div'>
        <CustomComponents.RealTimeTrend />
      </div>
      <div className='signal-main-div'>
        <CustomComponents.Signal />
      </div>
    </div>
  )
}