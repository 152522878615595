import { configureStore, isPlain  } from "@reduxjs/toolkit";
import  mainSlice  from "./MainSlice";
export const store = configureStore({
  reducer: {
    mainSlice,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: (value) =>
          value instanceof File || Blob || isPlain(value), // Allow File objects
      },
    }),
});