import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSignal } from '../Redux/MainSlice';
import Chart, {
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  Label,
  Format,
  ValueAxis,

  Tooltip,
} from 'devextreme-react/chart';
// import { dataSource } from './data.js';
import { useState, useEffect } from 'react';
// import { dataSource } from './data';

const customizeTooltip = (arg) => ({
  text: `Open: ${arg.openValue}<br/>
Close: ${arg.closeValue}<br/>
High: ${arg.highValue}<br/>
Low: ${arg.lowValue}<br/>`,
});

function RealTimeTrend() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    const ws = new WebSocket("ws://173.208.142.30:8000/ws/data");
    ws.onmessage = (event) => {
      const receivedData = JSON.parse(event.data);
      const forexData = receivedData["forex_data"];
      const forexDate = receivedData["forex_date"];
      const forexTime = receivedData["forex_time"];
      const predictedValues = receivedData['predicted_value'];
      const upperBand = receivedData["upper_band"];
      const lowerBand = receivedData['lower_band'];
      const signal = receivedData["signal"];
      const predictedSignals = [];


      // console.log("predictedValues", forexDate);
      const calcTrend = (price) => {
        const currentPrice = forexData[forexData.length - 1][3];
        console.log("PRICE", predictedValues)
        if (price >= forexData[forexData.length - 1][3]) {
          return "Bullish"
        }
        else {
          return "Bearish"
        }
      }
      for (let i = 0; i < 5; i++) {
        predictedSignals.push(calcTrend(predictedValues[0][i]));
      }

      dispatch(setSignal({
        "predictedSignals": predictedSignals,
        "upperBand": upperBand,
        "lowerBand": lowerBand,
        "signal": signal
      }))

      let dataSource = [];
      if (forexData) {
        forexData.map((item, index) => {
          const [year, month, day] = forexDate[index][0].split('-');
          const time = forexTime[index][0];
          // console.log("FOREXTIME", year, );
          dataSource.push(
            {
              date: new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(time)),
              l: item[2],
              h: item[1],
              o: item[0],
              c: item[3],
            }
          )
        })
      }
      setData(dataSource);

    };
    ws.onclose = () => console.log("WebSocket closed");

    return () => ws.close();
  }, []);

  return (
    <Chart className='candle-chart' title={{
      text: "ERU/USD LOT(Standard)",  // Set the title text here
      font: { color: '#FFFFFF', size: 16 }
    }} dataSource={data}>
      <CommonSeriesSettings argumentField="date" type="candlestick" />
      <Series
        name="E-Mart"
        openValueField="o"
        highValueField="h"
        lowValueField="l"
        closeValueField="c"
        reduction={{ color: '#d5265f' }}
        // appearance={{ upColor: '#08b3cf' }}
      />
      <ArgumentAxis>
        <Label format="shortDateShortTime" />
      </ArgumentAxis>
      <ValueAxis tickInterval={0.001} position='right'>
        <Label>
          <Format precision={3} />
        </Label>
      </ValueAxis>
      {/* <Export enabled={true} /> */}
      <Tooltip
        enabled={true}
        location="edge"
        customizeTooltip={customizeTooltip}
      />
    </Chart>
  );
}
export default RealTimeTrend;
