import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signal: null,
  predictedSignals: [],
  upperBand: null,
  lowerBand: null,
};


const mainSlice = createSlice({
  name: "mainSlice",
  initialState,
  reducers: {
    setSignal: (state, {payload}) => {
      const {predictedSignals, upperBand, lowerBand, signal} = payload;
      state.predictedSignals = predictedSignals;
      state.upperBand = upperBand;
      state.lowerBand = lowerBand;
      state.signal = signal;
    }
  },
});

export const {
  setSignal
} = mainSlice.actions;

export default mainSlice.reducer;
